<template>
    <el-footer style="padding-top: 20px">
        <el-divider></el-divider>
        <h3> 操作记录：</h3>
        <el-timeline class="opt-log">
            <el-timeline-item
                    :color="activity.color"
                    :key="index"
                    v-for="(activity, index) in divideLog"
            >
                【 {{`${activity.created_at}`}}】 {{activity.msg}}
            </el-timeline-item>
        </el-timeline>
    </el-footer>
</template>

<script>
  export default {
    name: 'LogList',
    data() {
      return {
        divideLog: []
      }
    },
    methods: {
      async getList() {
        let divideLog = await this.$api.kol_project_log({ project_id: this.$route.params.id })
        // "op_type": "create", //操作类型 create:创建 #0bbd87 confirm:确认 #409EFF check:审核 #F56C6C notify:通知 #E6A23C

        divideLog.list.forEach((itme) => {
            switch (itme.op_type) {
              case  'import-divide':
                itme.color = '#0bbd87'
                break
              case  'export':
                itme.color = '#0bbd87'
                break
              case  'import':
                itme.color = '#409EFF'
                break
              case  'finance-notify':
                itme.color = '#F56C6C'
                break
              case  'create':
                itme.color = '#E6A23C'
                break
              case  'update':
                itme.color = '#409EFF'
                break
            }
          },
          this.$nextTick(() => {
            this.divideLog = divideLog.list
          })
        )
      }
    },
    created() {
      this.getList()
    }
  }
</script>

<style scoped>

</style>