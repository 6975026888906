<template>
    <div>
        <el-card>
            <h3>项目详情
                <el-switch
                        @change="changeState"
                        active-color="#ff3176"
                        inactive-color="#13ce66"
                        style="margin-left: 10px"
                        v-model="projectState">
                </el-switch>
            </h3>
            <el-table :data="tableData" border style="width: 100%">
                <el-table-column align="center" label="序号" type="index" width="50"></el-table-column>
                <el-table-column align="center" label="项目名称" min-width="120px" prop="name"></el-table-column>
                <el-table-column align="center" label="平台" min-width="120px">
                    <template slot-scope="{row}">
                        <span>{{ row.platform_code==='douyin'?'抖音':'小红书'}}</span>
                    </template>
                </el-table-column>

                <el-table-column align="center" label="合作品牌" min-width="60px" prop="brand"></el-table-column>
                <el-table-column align="center" label="提报日期" min-width="50px" prop="report_time"></el-table-column>
                <el-table-column align="center" label="合作日期" prop="co_time"></el-table-column>
                <el-table-column align="center" label="提报红人数" prop="report_total"></el-table-column>
                <el-table-column align="center" label="合作红人数" prop="co_total"></el-table-column>
                <el-table-column align="right" header-align="center" label="项目成本价" prop="cost_price"></el-table-column>
                <el-table-column align="right" header-align="center" label="项目成交价" prop="trans_price"></el-table-column>
                <el-table-column align="center" label="项目利润率" prop="profit_rate">
                    <template slot-scope="{row}">
                        <span>{{ row.profit_rate!=0?row.profit_rate+'%':0}}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="项目对接人" prop="counterpart_name"></el-table-column>
                <el-table-column align="center" label="备注" prop="remark"></el-table-column>
            </el-table>
            <h3>明细
              <export-link :can-export="userPermissions.indexOf('kol_project_export') !== -1"
                           :lintTitle="'导出明细'"
                           @export="downLoad"
                           class="download"
                           ref="refExportLink"/>
              <div class="update download" v-if="tableData[0]?.status!=1">
                <el-upload
                    :before-upload="beforeUpload"
                    :data="uploadInfo"
                    :file-list="file"
                    :headers="uploadHeaders"
                    :on-change="handleChange"
                    :on-success="importSuccess"
                    :show-file-list="false"
                    action="/admin_api/kol_project/import"
                    class="upload-demo"
                    multiple
                >
                  <el-link icon="el-icon-upload2" size="big" style="font-weight: bold;font-size: 16px"
                           type="primary">导入明细
                  </el-link>
                </el-upload>
              </div>

            </h3>
          <div class="default-table">
            <el-table :data="tableDataDetails" border>
              <el-table-column align="center" label="序号" type="index" width="55"></el-table-column>
              <el-table-column align="center" label="红人昵称" min-width="120"
                               prop="kol_info.nickname"></el-table-column>
              <el-table-column align="center" label="个人/机构(名称)" min-width="120px"
                               prop="kol_info.organ_name"></el-table-column>
              <el-table-column align="center" label="媒介" min-width="100" prop="kol_info.referrer"
                               show-overflow-tooltip></el-table-column>
              <el-table-column align="center" label="资源来源" min-width="100" prop="kol_info.source"
                               show-overflow-tooltip></el-table-column>
              <el-table-column align="center" label="账号标签" min-width="120"
                               show-overflow-tooltip>
                <template slot-scope="{row}">
                <span>{{     row.kol_info?.account_label?.[0] != 'Array' ? row.kol_info?.account_label.toString() : ''     }}</span>
                </template>
              </el-table-column>
              <el-table-column :key="Math.random()" align="center" label="性别" min-width="80"
                               show-overflow-tooltip>
                <template slot-scope="{row}">
                  <span>{{ row.kol_info?.sex == '1' ? '男' : '女' }}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="肤质" prop="kol_info.skin_type" show-overflow-tooltip
                               width="80"></el-table-column>
              <el-table-column align="center" label="链接" prop="kol_platform.home_page_link" show-overflow-tooltip
                               width="120"></el-table-column>

              <template v-if="platformName==='douyin'">
                <el-table-column :key="Math.random()" label="粉丝量(W)"
                                 prop="kol_platform.fans_total"></el-table-column>
                <el-table-column :key="Math.random()" label="赞藏数(W)"
                                 prop="kol_platform.like_total"></el-table-column>
                <el-table-column align="center" label="星图价格">
                  <el-table-column :key="Math.random()" align="right" label="20s"
                                   prop="kol_platform.price_json.opened_star_price.20s" width="120">
                    <template slot-scope="{row}">
                   <span>
                  {{
                       row.kol_platform?.price_json?.opened_star_price ? moneyFormat(row.kol_platform?.price_json?.opened_star_price?.['20s']) : 0
                     }}</span>
                    </template>

                  </el-table-column>
                  <el-table-column :key="Math.random()" align="right" label="60s" width="120">
                    <template slot-scope="{row}">   <span>
                  {{
                        row.kol_platform?.price_json?.opened_star_price ? moneyFormat(row.kol_platform?.price_json?.opened_star_price?.['60s']) : 0
                      }}
                    </span>
                    </template>
                  </el-table-column>
                </el-table-column>
                <el-table-column align="center" label="非星图价格">
                  <el-table-column :key="Math.random()" align="right" label="单推"
                                   width="120">
                    <template slot-scope="{row}">
                                  <span>  {{
                                      row.kol_platform?.price_json?.not_opened_star_price ? moneyFormat(row.kol_platform?.price_json?.not_opened_star_price?.single) : 0
                                    }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column :key="Math.random()" align="right" label="合计"
                                   width="120">
                    <template slot-scope="{row}">
                   <span>
                  {{
                       row.kol_platform?.price_json?.not_opened_star_price ? moneyFormat(row.kol_platform?.price_json?.not_opened_star_price?.union) : 0
                     }}</span>
                    </template>
                  </el-table-column>
                </el-table-column>
                <el-table-column align="center" label="数据近30天">
                  <el-table-column :key="Math.random()" align="center" label="平均播放量w"
                                   prop="kol_platform.month_play_total"
                                   width="120"></el-table-column>
                  <el-table-column :key="Math.random()" align="center" label="平均点赞量w"
                                   prop="kol_platform.month_zan_total"
                                   width="120"></el-table-column>
                </el-table-column>
              </template>
                    <template v-else>
                        <el-table-column :key="Math.random()" label="擅长"
                                         prop="kol_info.skill.display_name"></el-table-column>

                        <el-table-column :key="Math.random()" label="是否报备" prop="kol_platform.is_report">
                            <template slot-scope="{row}">
                              <span>{{ row?.is_report == '1' ? '是' : '否' }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column :key="Math.random()" align="center" label="图文价格">
                            <el-table-column :key="Math.random()" align="right" label="报备"

                                             width="150">
                                <template slot-scope="{row}">
                   <span>
                  {{
                       row.kol_platform?.price_json?.image_text_price ? moneyFormat(row.kol_platform?.price_json?.image_text_price?.reported) : 0
                     }}</span>
                                </template>
                            </el-table-column>

                            <el-table-column :key="Math.random()" align="right" label="非报备"

                                             width="150 ">
                                <template slot-scope="{row}">
                   <span>
                  {{
                       row.kol_platform?.price_json.image_text_price ? moneyFormat(row.kol_platform?.price_json?.image_text_price?.not_reported) : 0
                     }}</span>
                                </template>

                            </el-table-column>
                        </el-table-column>
                        <el-table-column align="center" label="视频价格">
                            <el-table-column align="right" label="报备"
                                             prop="kol_platform.price_json.video_price.reported"
                                             width="150">
                                <template slot-scope="{row}">
                   <span>
                  {{
                       row.kol_platform?.price_json?.video_price ? moneyFormat(row.kol_platform?.price_json?.video_price?.reported) : 0
                     }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column align="right" label="非报备"
                                             prop="kol_platform.price_json.video_price.not_reported" width="150">
                              <template slot-scope="{row}">
                   <span>
                  {{
                       row.kol_platform?.price_json?.video_price ? moneyFormat(row.kol_platform?.price_json?.video_price?.not_reported) : 0
                     }}</span>
                              </template>
                            </el-table-column>
                        </el-table-column>
                    </template>
              <el-table-column align="center" label="粉丝画像">
                <el-table-column :key="Math.random()" align="center" label="性别"
                >
                  <template slot-scope="{row}">
                              <span>{{
                                  row.kol_info?.fans_info?.[0]?.sex == '1' ? '男' : row.kol_info?.fans_info?.[0].sex == '' ? '' : '女'
                                }}</span>
                  </template>
                </el-table-column>
                <el-table-column :key="Math.random()" align="center" label="比例%">
                </el-table-column>
                <el-table-column :key="Math.random()" align="center" label="人群标签TOP">
                  <template slot-scope="{row}">
                              <span>{{
                                  row.kol_info?.fans_info?.[0]?.label ? row.kol_info?.fans_info?.[0]?.label.toString() : ''
                                }}</span>
                  </template>
                </el-table-column>
                <el-table-column :key="Math.random()" align="center" label="主要年龄">
                  <template slot-scope="{row}">
                              <span>{{
                                  row.kol_info?.fans_info?.[0]?.master_age ? row.kol_info?.fans_info?.[0]?.master_age.toString() : ''
                                }}</span>
                  </template>
                </el-table-column>
              </el-table-column>
                    <el-table-column :key="Math.random()" align="center" label="过往合作品牌" min-width="120"
                                      show-overflow-tooltip>
                        <template slot-scope="{row}">
                            <span>{{row.kol_info?.co_brand?row.kol_info?.co_brand.toString():'' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :key="Math.random()" align="center" label="配合度" prop="kol_info.fit"
                                     width="80"></el-table-column>
                    <el-table-column :key="Math.random()" align="center" label="联系方式" min-width="150"
                                     show-overflow-tooltip>
                        <template slot-scope="{row}">
                            <span>{{row.kol_info?.contact_mode?.mode==''?'':row.kol_info?.contact_mode?.mode=='mobile'?'手机：':'微信：' }}{{ row.kol_info?.contact_mode?.value!=''&&row.kol_info?.contact_mode?.value!=null?row.kol_info?.contact_mode?.value:'暂无'}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :key="Math.random()" align="center" label="(红人资料)备注" min-width="120"
                                     prop="kol_info.remark" show-overflow-tooltip></el-table-column>
                    <el-table-column :key="Math.random()" align="center" label="提报日期" min-width="100"
                                     prop="project.report_time" show-overflow-tooltip></el-table-column>
                    <el-table-column :key="Math.random()" align="center" label="项目名称" min-width="100"
                                     prop="project.name" show-overflow-tooltip></el-table-column>
                    <el-table-column :key="Math.random()" align="center" label="合作品牌" min-width="100"
                                     prop="project.brand" show-overflow-tooltip></el-table-column>
                    <el-table-column :key="Math.random()" align="center" label="(提报)备注" min-width="100"
                                     prop="project.remark" show-overflow-tooltip></el-table-column>
                    <el-table-column :key="Math.random()" align="center" label="合作日期" min-width="100"
                                     prop="co_time" show-overflow-tooltip></el-table-column>
                    <el-table-column :key="Math.random()" align="center" label="成本价" min-width="100"
                                     prop="cost_price" show-overflow-tooltip></el-table-column>
                    <el-table-column :key="Math.random()" align="center" label="成交金额" min-width="100"
                                     prop="trans_price" show-overflow-tooltip></el-table-column>
                    <el-table-column :key="Math.random()" align="center" label="利润率(%)" min-width="100"
                                     prop="profit_rate" show-overflow-tooltip>
                        <template slot-scope="{row}">
                            <span>{{row?.profit_rate?row?.profit_rate+'%':'' }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-card>
        <LogList></LogList>
    </div>
</template>

<script>
import { numberFormat } from '@/utils/utils'
import { mapGetters } from 'vuex'
import ExportLink from '@/components/export/ExportLink'
import { getToken } from '@/utils/auth'
import LogList from '@/pages/kol/components/LogList'


export default {
  name: 'Detail',
  components: {
    ExportLink, LogList
  },
  data() {
    return {
      tableData: [],
      tableDataDetails: [],
      platformName: '',
      projectState: true,
      file: [],
      uploadHeaders: { 'HYPERF-SESSION-ID': null }

    }
  },
  computed: {
    ...mapGetters(['userPermissions', 'userInfo']),
    uploadInfo() {
      let info = this.tableData[0] ? this.tableData[0] : {}
      return { id: info.id, platform_code: info.platform_code }
    }
  },
  methods: {
    async getInfo() {
      let { info } = await this.$api.KolProjectInfo({ id: this.$route.params.id })
      this.$nextTick(() => {
        this.tableData = [info]
        this.getDetails(info.id)
        this.platformName = info.platform_code
        this.projectState = info?.status == '1' ? true : false
        })
      },
      async getDetails(id) {
        let { list } = await this.$api.KolProjectDetails({ id: id })
        this.$nextTick(() => {
          this.tableDataDetails = list
        })
      },
      moneyFormat(value) { // 金额 格式化
        return numberFormat(value, 0, '.', ',', 'round')
      },
      async changeState(val) {
        let data = await this.$api.saveKolProject({ id: this.$route.params.id, status: val })
        if (data) {
          val ? this.$notify.success('标记完结') : ''
          this.getInfo()
        }
      },
      async downLoad() {
        try {
          let newData = { project_id: this.$route.params.id, type: this.tableData[0].platform_code }
          var isFileSaverSupported = !!new Blob
          if (isFileSaverSupported) {
            // let searchCondition = this.handleSearchCondition()
            let response = await this.$api.downloadKolProject(newData)
            let name = `${this.tableData[0].name}_${this.tableData[0].brand}_${this.tableData[0].report_time}`
            this.$refs.refExportLink.exportSaveXlsx(response, name)
          }
        } catch (e) {
          this.$message.warning('浏览器不支持导出文件')
        }
      },
//上传模块
      handleChange(file) {
        this.file = [file]
      },
      importSuccess(response) {
        if (response.code === 200) {
          this.$notify.success('上传成功')
          this.submitted = []
          this.loadingSubmit = false
          this.showProgress = false
          this.dataList = response.data.list || []
          this.getInfo()
        } else {
          this.$notify.warning(response.msg)
        }
      },
      beforeUpload() {
        this.uploadLoading = true
        this.uploadHeaders['HYPERF-SESSION-ID'] = getToken()
      }

    },
    mounted() {
      this.getInfo()
    }
  }
</script>

<style lang="scss" scoped>
    .download {
        font-size: 18px;
        margin-left: 12px;
        color: #ff3176;
        cursor: pointer;
        display: inline-grid !important;
    }

    .update {
        > * {
            font-size: 16px;

        }

    }
</style>
